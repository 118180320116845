<template>
  <main class="main csgo-products-page page-inside">
    <div class="main-wrapper">
      <section class="section hero-section product-list-section">
        <div class="wrapper">
          <div class="title big">{{ $t('Products') }}</div>
          <div class="top">
            <!-- <div class="wrapper">
              <div class="left">
                <div :class="['filter-btn', {'active': filtersIsVisible}]" @click="openFilter">
                  <div class="filter-btn-wrapper">
                    <img src="./../assets/fillter.svg" class="img"/>
                    <span class="desc">Filter</span>
                    <img src="./../assets/arrow-down.svg" class="img"/>
                  </div>
                </div>
              </div>
              <div class="right">
                
              </div>
            </div> -->
            <div class="filters">
              <div class="filters-wrapper">
                <div class="select-container" v-if="sortOptions.length">
                  <div class="desc">{{ $t('Sort:') }}</div>
                  <Select2 class="select2 single" v-model="sort" 
                  :options="sortOptions" 
                  placeholder="Highest price first"
                  @select="selectSort($event)"
                  />
                </div>
                <div class="select-container" v-if="typeOptions.length">
                  <div class="desc">{{ $t('Type:') }}</div>
                  <Select2 class="select2 single" v-model="category" 
                  :options="typeOptions" 
                  placeholder="All types"
                  @select="selectCategory($event)"
                  />
                </div>
                <div class="select-container" v-if="qualityOptions.length">
                  <div class="desc">{{ $t('Quality:') }}</div>
                  <Select2 class="select2 single" v-model="quality" 
                  :options="qualityOptions" 
                  placeholder="All quality"
                  @select="selectQuality($event)"
                  />
                </div>
                <div class="select-container select-container-range">
                  <div class="desc">{{ $t('Price:') }}</div>
                  <div class="val min">
                    <input type="number" v-model="barMinValue"/>
                    <div class="desc">{{$parent.currency}}</div>
                  </div>
                  <div class="divider"></div>
                  <div class="val max">
                    <input type="number" v-model="barMaxValue"/>
                    <div class="desc">{{$parent.currency}}</div>
                  </div>
                </div>
                <div class="select-container select-container-reset">
                  <div class="reset" @click="resetFilters">
                    <div class="desc">{{ $t('Reset Filters') }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <transition name="fade">
            <div class="list products" v-if="productList">
              <div class="item" v-for="(item, i) in productList" :key="i">
                <div class="item-wrapper">
                  <div class="desc">{{item.category}}</div>
                  <a @click="$parent.goToProduct(item)" class="title">{{item.title}}</a>
                  <a @click="$parent.goToProduct(item)" class="preview">
                    <img :src="imgDomain + item.img_url" class="img"/>
                    <transition name="fade">
                      <span class="title" v-if="$parent.addToCartChosenItem == item.id">{{ $t('Added') }}</span>
                    </transition>
                  </a>
                  <div class="price-container">
                    <div class="price title">
                      <span class="discount" v-if="item.old_price != '0.00'">{{item.old_price}} {{$parent.currency}}</span>
                      <span>{{item.price}} <span class="currency">{{$parent.currency}}</span></span>
                    </div>
                    <a class="link" @click="$parent.addToCart(item, item.id)">
                      <span>{{ $t('Add to Cart') }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </transition>
          <pagination v-if="totalProducts" v-model="activePage" :records="totalProducts" :per-page="20" @paginate="changePage"/>
        </div>
        <Features/>
      </section>
    </div>
  </main>
</template>
<script>
import Select2 from 'vue3-select2-component';
import Pagination from 'vue-pagination-2';
import Features from '../components/Features.vue';

export default {
  name: 'CsGoProducts',
  props: [],
  components: {
    Select2,
    Pagination,
    Features
  },
  data: function() {
    return {
      imgDomain: '',
      filtersIsVisible: false,
      sort: 'desc',
      category: '',
      quality: '',
      activePage: 1,
      totalProducts: null,
      sortOptions: [
        {
          id: 'desc',
          text: 'Highest price first'
        }, 
        {
          id: 'asc',
          text:'Lowest price first'
        }
      ],
      categoryOptions: {},
      typeOptions: {},
      qualityOptions: {},
      barMinValue: 0,
      barMaxValue: 1200,
      productList: {}
    }
  },
  watch: {
      barMinValue: function() {
        this.activePage = 1;
        this.filterProducts();
      },
      barMaxValue: function() {
        this.activePage = 1;
        this.filterProducts();
      }
  },
  mounted() {
    this.imgDomain = process.env.VUE_APP_STATIC_DOMAIN;
    this.$http.get(process.env.VUE_APP_API + 'items/list?page=1&sort=desc')
    .then((res) => {
      this.productList = res.data.payload
      this.totalProducts = res.data.meta.total
    })
    .catch((res) => {
      if(res.response.status === 401) {
        this.$parent.openSignInModal();
      }
    })

    // this.getCategoryOptions();
    this.getQualityOptions();
    this.getTypeOptions();
    
  },
  methods: {
    selectSort(event) {
      this.activePage = 1;
      this.sort = event.id;
      this.filterProducts();
    },
    selectCategory(event) {
      this.activePage = 1;
      this.category = event.text;
      this.filterProducts();
    },
    selectQuality(event) {
      this.activePage = 1;
      this.quality = event.text;
      this.filterProducts();
    },
    resetFilters() {
      this.quality = 'All quality';
      this.typeOptions = 'All types';
      this.categoryOptions = '';
      this.qualityOptions = '';
      this.getCategoryOptions();
      this.getQualityOptions();
      this.barMinValue = 0;
      this.barMaxValue = 1200;
      this.filterProducts();
    },
    openFilter() {
      this.filtersIsVisible = !this.filtersIsVisible;
    },
    filterProducts() {

      let addCategory = '';
      if (this.category != 'All types') {
        addCategory = 'type=' + this.category
      } else {
        addCategory = '';
      }

      let addQuality = '';
      if (this.quality != 'All quality') {
        addQuality = '&quality=' + this.quality
      } else {
        addQuality = '';
      }
      let addPrice = '&price_from=' + this.barMinValue + '&price_till=' + this.barMaxValue;

      let addSort = '&sort=' + this.sort

      let addPage = '&page=' + this.activePage
      
      this.$http.get(process.env.VUE_APP_API + 'items/list?' + addCategory + addQuality + addPrice + addSort + addPage)
      .then((res) => {
        console.log(res)
        this.productList = res.data.payload
        this.totalProducts = res.data.meta.total
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    changePage(page) {
      this.activePage = page;
      this.filterProducts();
    },
    getCategoryOptions() {
      this.$http.get(process.env.VUE_APP_API + 'categories')
      .then((res) => {
        const newCategories = res.data.payload.map(({
          id: id,
          title: text,
          url: url
        }) => ({
          id,
          text,
          url
        }));
        newCategories.unshift({id: 0, text: 'All types', url: ''})
        this.categoryOptions = newCategories;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getTypeOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        let newType = res.data.payload.types;
        newType.unshift(this.$t('All types'));
        this.typeOptions = newType;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    },
    getQualityOptions() {
      this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
      .then((res) => {
        let newQuality = res.data.payload.qualities;
        newQuality.unshift('All quality');
        this.qualityOptions = newQuality;
      })
      .catch((res) => {
        if(res.response.status === 401) {
          this.$parent.openSignInModal();
        }
      })
    }
  }
}
</script>